.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  position: sticky;
  flex-shrink: 1;
  min-width: 0;
  border-radius: 8px;
  padding: 0px;
  border: 1px solid var(--token-NagwnEgqYDVz);
}
.rootisDisabled {
  background: var(--token-7u3Fjf0ZO4pV);
}
.rootinvalid {
  background: var(--token-f0Mcnp3uZyQy);
  border-color: var(--token-JYEEwSwHupuU);
}
.rootsize_small {
  padding: 4px 16px;
}
.rootsize_large {
  padding: 12px 16px;
}
.root:hover {
  border-color: var(--token-3hsdHs9SQrQP);
}
.root:focus-within {
  box-shadow: inset 0px 1px 4px 2px #0f2a410d;
  outline-style: none;
  border-radius: 8px;
  border-color: var(--token-pkN2y4iq-viG);
}
.rootisDisabled:hover {
  border-color: var(--token-NagwnEgqYDVz);
}
.rootinvalid:hover {
  border-color: var(--token-pmZ4Vyx2r26Q);
}
.rootinvalid:focus-within {
  box-shadow: inset 0px 1px 4px 2px #0f2a410d;
  outline: none;
  border-color: var(--token-pmZ4Vyx2r26Q);
}
.inputType:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.startIconContainer {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-right: 8px;
  width: auto;
  height: auto;
  display: none;
  padding: 0px;
  border: 0px solid transparent;
}
.startIconContainershowStartIcon {
  display: flex;
}
.slotTargetStartIconshowStartIcon {
  color: #90908c;
}
.svg__cuiG8 {
  position: relative;
  object-fit: cover;
  width: auto;
  height: 1em;
}
.text {
  font-family: var(--rubik);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--token-x0ltlSxVUZco);
  position: relative;
  width: auto;
  height: auto;
  max-width: 100%;
  display: none;
  padding: 8px 0px 8px 16px;
}
.textshowPrefix {
  display: block;
}
.input {
  width: 100%;
  left: auto;
  top: auto;
  background: #ffffff00;
  opacity: 1;
  height: 100%;
  outline-style: none;
  min-width: 0;
  min-height: 0;
  border-radius: 7px;
  padding: 8px 16px;
  border-width: 0px;
}
.inputshowEndIcon {
  padding-right: 28px;
}
.inputisDisabled {
  cursor: not-allowed;
}
.inputsize_small {
  padding: 4px 16px;
}
.inputsize_large {
  padding: 12px 16px;
}
.inputshowPrefix {
  padding-left: 8px;
}
.root:focus-within .input {
  outline: none;
}
.rootinvalid:focus-within .inputinvalid {
  outline: none;
}
.root .input:focus {
  opacity: 1;
  outline: none;
}
.root .input::placeholder {
  color: var(--token-x0ltlSxVUZco);
  opacity: 0.7;
}
.rootisDisabled .inputisDisabled::placeholder {
  color: var(--token-sJr9sbZwbyqK);
}
.root:focus-within .input:focus {
  outline: none;
}
.root:focus-within .input::placeholder {
  outline: none;
}
.endIconContainer {
  position: absolute;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  width: auto;
  right: 12px;
  display: none;
  padding: 0px;
  border: 0px solid transparent;
}
.endIconContainershowEndIcon {
  display: flex;
}
.hidePasswordIcon {
  position: relative;
  object-fit: cover;
  width: 12px;
  height: 12px;
  color: var(--token-sJr9sbZwbyqK);
  cursor: pointer;
  flex-shrink: 0;
}
.rootshowEndIcon .hidePasswordIconshowEndIcon:hover {
  color: var(--token-pkN2y4iq-viG);
}
.showPasswordIcon {
  position: relative;
  object-fit: cover;
  width: 12px;
  height: 12px;
  color: var(--token-sJr9sbZwbyqK);
  cursor: pointer;
  flex-shrink: 0;
}
.showPasswordIconshowEndIcon {
  display: block;
}
.rootshowEndIcon .showPasswordIconshowEndIcon:hover {
  color: var(--token-pkN2y4iq-viG);
}
